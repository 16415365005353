/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
// import { useNavigate } from 'react-router-dom'
import { contact } from '../utils/constants'

const Footer = () => {
  // const navigate = useNavigate()

  return (
    <div className="footer">
      <Container className="content">
        <Row>
          <Col md={6} sm={8} xs={12} className="d-flex align-items-center justify-content-center">
            <div className="logo-wrapper">
              <img src={require("../assets/logo.png")} alt="Logo" width={100} />
            </div>
            <div className="about-us d-sm-block d-none">
              <p className="khmer-bold title">អំពីយើង</p>
              <p className="pe-5 khmer">
                365 promotion ជាវេទិការផ្សព្វផ្សាយទំនិញមានប្រូម៉ូសិន និងបញ្ចុះតម្លៃគ្រប់ប្រភេទ!
              </p>
            </div>
          </Col>

          <Col xs={12} className="d-xs-block d-sm-none">
            <p className="khmer-bold title text-center">អំពីយើង</p>
            <p className="khmer px-5 text-center">
              365 promotion ជាវេទិការផ្សព្វផ្សាយទំនិញមានប្រូម៉ូសិន និងបញ្ចុះតម្លៃគ្រប់ប្រភេទ!
            </p>
          </Col>

          <Col sm={3} xs={12} className="company d-sm-none d-none d-md-block">
            <p className="khmer-bold title">ពត៏មានក្រុមហ៊ុន</p>
            <a className="khmer" href="/">អំពីយើង</a>
            <a className="khmer" href="/">ក្លាយជាដៃគូផ្សព្វផ្សាយ</a>
            <a className="khmer" href="/">ធ្វើការជាមួយ 365 Promotions</a>
            <a href="/">Privacy Policy</a>
            <a href="/">Term and Condition</a>
          </Col>

          <Col md={3} sm={4} xs={12} className="contact-us">
            <p className="khmer-bold title">ទំនាក់ទំនង</p>

            <a href={`tel:${contact.phone.replace(/\s*/g, '')}`}>{contact.phone}</a>
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </Col>
        </Row>
      </Container>

      <p className="all-right-reserved khmer">រក្សាសិទ្ធគ្រប់យ៉ាង, 365 Promotion &copy; 2022, 2018</p>

    </div>
  )
}

export default Footer
