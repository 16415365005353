import Immutable from 'seamless-immutable'
import { PROMOTION_TYPES } from '../types'

const initialState = Immutable({
  homepage: {},
  categories: {},
  search: {},
  detail: {},
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROMOTION_TYPES.GET_HOMEPAGE_DATA:
      return state
        .setIn(['homepage', action.categoryId, 'data'], [])
        .setIn(['homepage', action.categoryId, 'loading'], true)

    case PROMOTION_TYPES.SET_HOMEPAGE_DATA:
      return state
        .setIn(['homepage', action.categoryId, 'data'], action.data)
        .setIn(['homepage', action.categoryId, 'loading'], false)

    case PROMOTION_TYPES.SET_HOMEPAGE_LOADING:
      return state.setIn(['homepage', action.categoryId, 'loading'], action.val)

    case PROMOTION_TYPES.GET_CATEGORY_DATA:
      return state
        .setIn(
          ['categories', action.categoryId, 'data'],
          state.categories[action.categoryId]?.data || []
        )
        .setIn(
          ['categories', action.categoryId, 'page'],
          state.categories[action.categoryId]?.page || 1
        )
        .setIn(
          ['categories', action.categoryId, 'count'],
          state.categories[action.categoryId]?.count || 0
        )
        .setIn(
          ['categories', action.categoryId, 'loading'],
          state.categories[action.categoryId]?.loading || true
        )

    case PROMOTION_TYPES.SET_CATEGORY_DATA:
      return state
      .setIn(['categories', action.categoryId, 'data'], action.data)
      .setIn(['categories', action.categoryId, 'page'], action.page)
      .setIn(['categories', action.categoryId, 'count'], action.count)
      .setIn(['categories', action.categoryId, 'loading'], false)

    case PROMOTION_TYPES.SET_CATEGORY_LOADING:
      return state.setIn(['categories', action.categoryId, 'loading'], action.val)

    case PROMOTION_TYPES.GET_SEARCH_DATA:
      return state
        .setIn(['search', 'data'], state.search?.data || [])
        .setIn(['search', 'page'], state.search?.page || 1)
        .setIn(['search', 'count'], state.search?.count || 0)
        .setIn(['search', 'loading'], state.search?.loading || true)

    case PROMOTION_TYPES.SET_SEARCH_DATA:
      return state
        .setIn(['search', 'data'], action.data)
        .setIn(['search', 'page'], action.page)
        .setIn(['search', 'count'], action.count)
        .setIn(['search', 'loading'], false)

    case PROMOTION_TYPES.SET_SEARCH_LOADING:
      return state.setIn(['search', 'loading'], action.val)

    case PROMOTION_TYPES.SET_DETAIL_DATA:
      return state
        .setIn(['detail', action.promotionId, 'data'], action.data)
        .setIn(['detail', action.promotionId, 'loading'], false)

    case PROMOTION_TYPES.SET_DETAIL_LOADING:
      return state.setIn(['detail', action.promotionId, 'loading'], action.val)

    default:
      return state
  }
}

export default reducer
