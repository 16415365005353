import axios from 'axios'
import { PROMOTION_TYPES } from '../types'
import { endpoint } from '../../utils/constants'

const getHomepagePromotionsReducer = categoryId => ({
  type: PROMOTION_TYPES.GET_HOMEPAGE_DATA,
  categoryId,
})

const setHomepagePromotions = (categoryId, data) => ({
  type: PROMOTION_TYPES.SET_HOMEPAGE_DATA,
  categoryId,
  data,
})

const setHomepageLoading = (categoryId, val) => ({
  type: PROMOTION_TYPES.SET_HOMEPAGE_LOADING,
  categoryId,
  val
})

const getCategoryPromotionsReducer = (categoryId, page) => ({
  type: PROMOTION_TYPES.GET_CATEGORY_DATA,
  categoryId,
  page,
})

const setCategoryPromotions = (categoryId, data, count, page) => ({
  type: PROMOTION_TYPES.SET_CATEGORY_DATA,
  categoryId,
  data,
  page,
  count,
})

const setCategoryLoading = (categoryId, val) => ({
  type: PROMOTION_TYPES.SET_CATEGORY_LOADING,
  categoryId,
  val
})

const getSearchPromotionsReducer = page => ({
  type: PROMOTION_TYPES.GET_SEARCH_DATA,
  page,
})

const setSearchPromotions = (data, count, page) => ({
  type: PROMOTION_TYPES.SET_SEARCH_DATA,
  data,
  page,
  count,
})

const setSearchLoading = val => ({
  type: PROMOTION_TYPES.SET_SEARCH_LOADING,
  val
})

const setPromotionDetail = (promotionId, data) => ({
  type: PROMOTION_TYPES.SET_DETAIL_DATA,
  promotionId,
  data,
})

const setPromotionDetailLoading = (promotionId, val) => ({
  type: PROMOTION_TYPES.SET_DETAIL_LOADING,
  promotionId,
  val
})

const getHomepagePromotions = categoryId => async dispatch => {
  dispatch(getHomepagePromotionsReducer(categoryId))

  try {
    const url = `${endpoint.promotions}?category_id=${categoryId}&display_on_homepage=true`

    const { data } = await axios.get(url)

    dispatch(setHomepagePromotions(categoryId, data.promotions))
  } catch (e) {
    dispatch(setHomepageLoading(categoryId, false))
  }
}

const getCategoryPromotions = (categoryId, page = 1) => async dispatch => {
  dispatch(getCategoryPromotionsReducer(categoryId, page))

  try {
    const limit = 60
    const offset = (page - 1) * limit
    const url = `${endpoint.promotions}?category_id=${categoryId}&limit=${limit}&offset=${offset}`

    const { data } = await axios.get(url)
    const numberOfPage = Math.ceil(data.count / limit)

    dispatch(setCategoryPromotions(categoryId, data.promotions, data.count, numberOfPage))
  } catch (e) {
    dispatch(setCategoryLoading(categoryId, false))
  }
}

const getSearchPromotions = (search, page = 1) => async dispatch => {
  dispatch(getSearchPromotionsReducer(page))

  try {
    const limit = 60
    const offset = (page - 1) * limit
    const url = `${endpoint.promotions}?q=${search}&limit=${limit}&offset=${offset}`

    const { data } = await axios.get(url)
    const numberOfPage = Math.ceil(data.count / limit)

    dispatch(setSearchPromotions(data.promotions, data.count, numberOfPage))
  } catch (e) {
    dispatch(setSearchLoading(false))
  }
}

const getPromotionDetail = id => async dispatch => {
  try {
    const url = `${endpoint.promotions}/${id}`

    const { data } = await axios.get(url)

    dispatch(setPromotionDetail(id, data))
  } catch (e) {
    dispatch(setPromotionDetailLoading(id, false))
  }
}

const actions = {
  getHomepagePromotions,
  setHomepagePromotions,
  setHomepageLoading,

  getCategoryPromotions,
  setCategoryPromotions,
  setCategoryLoading,

  getSearchPromotions,
  setSearchPromotions,
  setSearchLoading,

  getPromotionDetail,
  setPromotionDetail,
  setPromotionDetailLoading,
}

export default actions
