import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Container, Row, Col, ButtonGroup, Button, Modal } from 'react-bootstrap'
import { get, size } from 'lodash'
import { FiFilter } from 'react-icons/fi'
import { getAnalytics, logEvent } from "firebase/analytics"

import BannerActions from '../../redux/actions/banners'
import CategoryActions from '../../redux/actions/categories'
import PromotionActions from '../../redux/actions/promotions'
import useBreakpoint from '../../hooks/useBreakpoint'

import NotFound from '../../components/NotFound'
import PromotionItem from '../../components/PromotionItem'

const analytics = getAnalytics()

const findCategory = (categories, slug) => {
  const categoryCount = categories.length

  for (let index = 0; index < categoryCount; index++) {
    const category = categories[index];
    const categoryWithSub = [category, ...category.children]

    const cat = categoryWithSub.find(c => c.slug === slug)

    if (cat) return cat
  }
}

const Categories = () => {
  const { slug } = useParams()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()
  const [searchParams] = useSearchParams()

  const { data: banners } = useSelector(state => state.banners)
  const { data: categories } = useSelector(state => state.categories)
  const { categories: categoryPromotions, search: searchPromotions } = useSelector(state => state.promotions)

  const [page, setPage] = useState(1)
  const [showRelatedCategories, setShowRelatedCategories] = useState(false)

  const isSearchPage = pathname === '/search'
  const q = searchParams.get('q')

  const category = findCategory(categories, slug)
  const isSubCategory = Boolean(category?.parent_id)
  const parentCategory = isSubCategory ? categories.find(c => c.id === category?.parent_id) : category
  let relatedCategories = size(parentCategory?.children) > 0 ? parentCategory?.children : categories

  const count = isSearchPage
    ? get(searchPromotions, 'count', 0)
    : get(categoryPromotions, [category?.id, 'count'], 0)
  const loading = isSearchPage
    ? get(searchPromotions, 'loading', true)
    : get(categoryPromotions, [category?.id, 'loading'], true)
  const totalPages = isSearchPage
    ? get(searchPromotions, 'page', 1)
    : get(categoryPromotions, [category?.id, 'page'], 1)
  const promotions = isSearchPage
    ? get(searchPromotions, 'data', [])
    : get(categoryPromotions, [category?.id, 'data'], [])

  const isSmallScreen = ['sm', 'xs'].includes(breakpoint)

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(CategoryActions.getCategories())
    }
  }, [dispatch, categories])

  useEffect(() => {
    if (category) {
      setShowRelatedCategories(false)
      dispatch(PromotionActions.getCategoryPromotions(category.id, page))
    }
  }, [dispatch, category, page])

  useEffect(() => {
    if (q) {
      dispatch(PromotionActions.getSearchPromotions(q, page))
    }
  }, [dispatch, q, page])

  useEffect(() => {
    if (banners.length === 0) {
      dispatch(BannerActions.getBanners({ platform: isSmallScreen ? 'mobile' : 'web' }))
    }
  }, [dispatch, banners, isSmallScreen])

  const onSideMenuClick = category => {
    logEvent(analytics, 'click_category_sidemenu', { item_id: category.id, item_name: category.name })
  }

  const onBannerClick = banner => {
    logEvent(analytics, 'click_banner_category_listing', { item_id: banner.id, item_name: banner.title })

    window.open(banner.url)
  }

  if (categories.length > 0 && !category && !isSearchPage) {
    return (
      <NotFound />
    )
  }

  const sideBanners = banners.filter(banner => banner.placement === 'category-listing' && banner.category_id === category?.id)

  return (
    <Container data-page="categories">
      <Row>
        <Col sm={12} md={3}>
          {isSmallScreen ? (
            <Modal centered show={showRelatedCategories} onHide={() => setShowRelatedCategories(false)}>
              <Modal.Body>
                <h6 className="mb-3 fw-bold">Related Categories</h6>
                {relatedCategories.map(c => (
                  <Link to={`/${c.slug}`} onClick={() => onSideMenuClick(c)} key={c.id} className="text-decoration-none">
                    <p className="mb-1">{c.name}</p>
                  </Link>
                ))}
              </Modal.Body>
            </Modal>
          ) : (
            <div>
              <h6 className="mb-3 fw-bold">Related Categories</h6>
              <ul>
                  {parentCategory && size(parentCategory?.children) > 0 && (
                    <Link
                      to={`/${parentCategory.slug}`}
                      onClick={() => onSideMenuClick(parentCategory)}
                      className={`text-decoration-none ${slug === parentCategory.slug ? 'active' : '' }`}
                    >
                      <li>{parentCategory.name}</li>
                    </Link>
                  )}

                {relatedCategories.map(c => (
                  <Link
                    key={c.id}
                    to={`/${c.slug}`}
                    onClick={() => onSideMenuClick(c)}
                    className={`text-decoration-none ${slug === c.slug ? 'active' : ''}`}
                  >
                    <li>{c.name}</li>
                  </Link>
                ))}
              </ul>

              {sideBanners.length > 0 && (
                <div className="mt-3">
                  <img
                    src={banners[0].image_url}
                    alt={banners[0].title}
                    onClick={e => onBannerClick(banners[0])}
                    className='clickable'
                    style={{ maxWidth: '90%' }}
                  />
                </div>
              )}
            </div>
          )}
        </Col>
        <Col sm={12} md={9}>
          <div className="d-flex justify-content-between">
            <h6 className="mb-3 fw-bold">Promotions: {count} Result Found</h6>

            {isSmallScreen && (
              <span onClick={() => setShowRelatedCategories(true)}>
                <FiFilter className="me-1" />
                Filter
              </span>
            )}
          </div>

          <Row>
            {loading && promotions.length === 0 ? (
              Array.from({ length: 1 }).map((_, index) => (
                <Col xs={6} md={4} lg={3} key={index}><PromotionItem loading /></Col>
              ))
            ) : (
              promotions.map(promotion => (
                <Col xs={6} md={4} lg={3} key={promotion.id}>
                  <PromotionItem promotion={promotion} />
                </Col>
              ))
            )}
          </Row>
        </Col>
      </Row>

      {totalPages > 1 && (
        <Row>
          <Col xs={12} className="mt-3 text-end navigation">
            <ButtonGroup>
              <Button onClick={() => setPage(page - 1)} disabled={page === 1}>Prev</Button>
              <Button onClick={() => setPage(page + 1)} disabled={page === totalPages}>Next</Button>
            </ButtonGroup>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default Categories
