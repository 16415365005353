export const CATEGORY_TYPES = {
  SET_DATA: 'SET_CATEGORY_DATA',
  SET_LOADING: 'SET_CATEGORY_LOADING',
}

export const PROMOTION_TYPES = {
  GET_HOMEPAGE_DATA: 'GET_PROMOTION_HOMEPAGE_DATA',
  SET_HOMEPAGE_DATA: 'SET_PROMOTION_HOMEPAGE_DATA',
  SET_HOMEPAGE_LOADING: 'SET_PROMOTION_HOMEPAGE_LOADING',

  GET_CATEGORY_DATA: 'GET_PROMOTION_CATEGORY_DATA',
  SET_CATEGORY_DATA: 'SET_PROMOTION_CATEGORY_DATA',
  SET_CATEGORY_LOADING: 'SET_PROMOTION_CATEGORY_LOADING',

  GET_SEARCH_DATA: 'GET_PROMOTION_SEARCH_DATA',
  SET_SEARCH_DATA: 'SET_PROMOTION_SEARCH_DATA',
  SET_SEARCH_LOADING: 'SET_PROMOTION_SEARCH_LOADING',

  GET_DETAIL_DATA: 'GET_PROMOTION_DETAIL_DATA',
  SET_DETAIL_DATA: 'SET_PROMOTION_DETAIL_DATA',
  SET_DETAIL_LOADING: 'SET_PROMOTION_DETAIL_LOADING',
}

export const BANNER_TYPES = {
  SET_DATA: 'SET_BANNER_DATA',
  SET_LOADING: 'SET_BANNER_LOADING',
}
