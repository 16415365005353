import React from 'react'
import { Container, Carousel } from 'react-bootstrap'

import useBreakpoint from '../../hooks/useBreakpoint'

const MOBILE_BANNERS = [
  "https://s3.ap-southeast-1.amazonaws.com/365-promotions.com/banners/1660493255377-mobile-cover-2.webp",
  "https://s3.ap-southeast-1.amazonaws.com/365-promotions.com/banners/1660493164943-mobile-cover-1.webp",
  "https://s3.ap-southeast-1.amazonaws.com/365-promotions.com/banners/1660493627163-mobile-cover-3.webp",
]

const WEB_BANNERS = [
  "https://s3.ap-southeast-1.amazonaws.com/365-promotions.com/banners/1660493785345-web-cover-3.webp",
  "https://s3.ap-southeast-1.amazonaws.com/365-promotions.com/banners/1660495743342-web-cover-2.webp",
  "https://s3.ap-southeast-1.amazonaws.com/365-promotions.com/banners/1660495778377-web-cover-1.webp",
]

const Banner = () => {
  const breakpoint = useBreakpoint()
  const isSmallScreen = ['xs', 'sm'].includes(breakpoint)
  const banners = isSmallScreen ? MOBILE_BANNERS : WEB_BANNERS

  return (
    <Container className=".container-md">
      <Carousel interval={3000}>
        {banners.map((banner, index) => (
          <Carousel.Item key={index}>
            <img
              src={banner}
              className="d-block w-100"
              alt={banner}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  )
}

export default Banner
