import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAizlG2FmWpRDqn2Wz1sY2BNf-aYaXdCfI",
  authDomain: "promotions-365.firebaseapp.com",
  projectId: "promotions-365",
  storageBucket: "promotions-365.appspot.com",
  messagingSenderId: "497573194",
  appId: "1:497573194:web:c7129f8ae5d04a765875b3",
  measurementId: "G-L8N7ZQG7M4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);