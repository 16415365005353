import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getAnalytics, logEvent } from "firebase/analytics"

const analytics = getAnalytics()

const PromotionItem = ({ promotion, loading }) => {
  const navigate = useNavigate()
  const imageUrl = promotion?.image_url || ''
  const fileName = imageUrl.split('/').pop()
  const thumb = imageUrl.replace(fileName, `thumb-${fileName}`)

  const onPromotionClick = () => {
    navigate(`/promotions/${promotion.id.split("-")[0]}`)
    logEvent(analytics, 'click_promotion_item', { item_id: promotion.id, item_name: promotion.name })
  }

  return (
    <div
      onClick={onPromotionClick}
      className="position-relative mb-4 promotion-item clickable"
    >
      <div className="image-wrapper">
        {!loading && (
          <div
            alt="Promotion"
            className="shadow mb-2 w-100 h-100"
            style={{ backgroundImage: `url(${thumb})` }}
          />
        )}
      </div>

      {promotion?.discount_label && (
        <span className={`promotion-badge text-truncate ${promotion.discount_type.toLowerCase()}`}>{promotion.discount_label}</span>
      )}

      {
        loading
        ? <div className="placeholder-text" />
        : <p className="text-center mt-2">{promotion.title}</p>
      }
    </div>
  )
}

export default PromotionItem
