import React from 'react'
import { Routes, Route } from "react-router-dom"

import Layout from './layout'

import Home from './containers/home'
import Categories from './containers/categories'
import Promotion from './containers/promotion'

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Categories />} />
        <Route path="/promotions/:id" element={<Promotion />} />
        <Route path="/:slug" element={<Categories />} />
      </Routes>
    </Layout>
  )
}

export default App;
