import axios from 'axios'
import { BANNER_TYPES } from '../types'
import { endpoint } from '../../utils/constants'

const setBanners = data => ({
  type: BANNER_TYPES.SET_DATA,
  data,
})

const setLoading = val => ({
  type: BANNER_TYPES.SET_LOADING,
  val
})

const getBanners = ({ platform }) => async dispatch => {
  dispatch(setLoading(true))

  try {
    const { data } = await axios.get(endpoint.banners + `?platform=${platform}`)
    dispatch(setBanners(data))
  } catch (e) {
    dispatch(setLoading([]))
  }
}

const actions = {
  setLoading,
  setBanners,
  getBanners,
}

export default actions
