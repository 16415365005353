import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { getAnalytics, logEvent } from "firebase/analytics"

const analytics = getAnalytics()

const Categories = ({ categories, loading }) => {
  const navigate = useNavigate()

  const handleClick = category => {
    navigate(`/${category.slug}`)
    logEvent(analytics, 'click_homepage_category_horizontal', { item_id: category.id, item_name: category.name })
  }

  const renderPlaceholder = () => loading && Array(14).fill().map((_, index) => (
    <div key={index} className="text-center" style={index === 0 ? { marginLeft: '-0.75rem' } : {}}>
      <div className="placeholder-img mb-2" />
      <div className="placeholder-text" />
    </div>
  ))

  return (
    <Container className=".container-md mt-5 mb-3 categories">
      <h5 className="fw-bold">Categories</h5>

      <div className="d-flex mt-3 overflow-auto hide-scrollbar">
        {renderPlaceholder()}

        {!loading && categories.map((category, index) => (
          <div
            key={category.id}
            className="text-center clickable"
            onClick={() => handleClick(category)}
            style={index === 0 ? { marginLeft: '-0.75rem' } : {}}
          >
            <div className="category-img mb-2 shadow">
              <img src={category.image_url} alt={category.name} width={100} />
            </div>
            <p className="text-truncate">{category.name}</p>
          </div>
        ))}
      </div>

    </Container>
  )
}

export default Categories
