import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { BiPhoneCall } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaInstagram, FaTelegramPlane, FaFacebookF } from 'react-icons/fa'
import { TbBrandYoutube } from 'react-icons/tb'

import { contact } from '../utils/constants'

const TopHeader = () => {
  const openLink = (link, openNewTab) => {
    if (openNewTab)
      window.open(link)
    else
      window.location.href = link
  }

  return (
    <div className="top-header">
        <Container>
          <Row>
            <Col md={12} lg={8}>
              <div className="contact-container d-none d-sm-none d-md-flex">
                <div className="contact">
                  <BiPhoneCall color="white" /> <span><a href={`tel:${contact.phone.replace(/\s*/g, '')}`}>{contact.phone}</a></span>
                </div>
                <div className="contact">
                  <FaTelegramPlane color="white" /> <span><a href={`tel:${contact.phone.replace(/\s*/g, '')}`}>{contact.phone}</a></span>
                </div>
                <div className="contact">
                  <MdOutlineEmail color="white" /> <span><a href={`mailto:${contact.email}`}>{contact.email}</a></span>
                </div>
              </div>
            </Col>
            <Col md={12} lg={4}>
              <div className="social-media-container">
                <div className="social-media-wrapper d-flex d-md-none phone">
                  <BiPhoneCall onClick={() => openLink(`tel:${contact.phone.replace(/\s*/g, '')}`)} />
                </div>
                <div className="social-media-wrapper d-flex d-md-none email">
                  <MdOutlineEmail onClick={() => openLink(`mailto:${contact.email}`)} />
                </div>
                <div className="social-media-wrapper facebook">
                <FaFacebookF onClick={() => openLink(contact.facebook, true)} />
                </div>
                <div className="social-media-wrapper instagram">
                  <FaInstagram onClick={() => openLink(contact.instagram, true)} />
                </div>
                <div className="social-media-wrapper youtube">
                  <TbBrandYoutube onClick={() => openLink(contact.youtube, true)} />
                </div>
                <div className="social-media-wrapper telegram">
                  <FaTelegramPlane onClick={() => openLink(contact.telegram, true)} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default TopHeader
