import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { get, chunk } from 'lodash'
import { Link } from 'react-router-dom'
import { getAnalytics, logEvent } from "firebase/analytics"

import useBreakpoint from '../../hooks/useBreakpoint'
import PromotionActions from '../../redux/actions/promotions'

import PromotionItem from '../../components/PromotionItem'

const analytics = getAnalytics()

const generatingPromotions = (promotions, loading, isMobileScreen) => {
  if (loading) return Array(12).fill().map((_, i) => ({ id: i }))

  const promotionCount = promotions.length

  if (isMobileScreen && promotionCount % 4 > 0) {
    const placeholderCount = 4 - (promotionCount % 4)
    const newPromotions = [...promotions]

    Array(placeholderCount).fill().forEach((_, i) => newPromotions.push({ id: i, showAvailableAds: true }))

    return newPromotions
  }

  return promotions
}

const TitlePlaceholder = () => (
  <div className="d-flex align-items-center">
    <div className="placeholder-icon" />
    <div className="placeholder-title ms-2" />
  </div>
)

const AvailableAds = () => (
  <div className="position-relative mb-4 promotion-item">
    <div className="image-wrapper">
      <div
        alt="Promotion"
        className="shadow mb-2 w-100 h-100"
        style={{ backgroundImage: `url(${require("../../assets/available-ads.png")})` }}
      />
    </div>
    <p className="text-center mt-2">Promotion Available</p>
  </div>
)

const CategoryPromotions = ({ category, banners, categoryLoading, className = "" }) => {
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()
  const isSmallScreen = ['xs', 'sm'].includes(breakpoint)

  const promotionReducer = useSelector(state => state.promotions)

  const promotions = get(promotionReducer, ['homepage', category?.id, 'data'], [])
  const loading = get(promotionReducer, ['homepage', category?.id, 'loading'], true)

  useEffect(() => {
    if (category) {
      dispatch(PromotionActions.getHomepagePromotions(category?.id))
    }
  }, [dispatch, category])

  const onTitleClick = () => {
    logEvent(analytics, 'click_homepage_category_vertical', { item_id: category.id, item_name: category.name })
    logEvent(analytics, 'click_homepage_category_vertical_title', { item_id: category.id, item_name: category.name })
  }

  const onSeeMoreClick = () => {
    logEvent(analytics, 'click_homepage_category_vertical', { item_id: category.id, item_name: category.name })
    logEvent(analytics, 'click_homepage_category_vertical_see_more', { item_id: category.id, item_name: category.name })
  }

  const onBannerClick = banner => {
    logEvent(analytics, 'click_homepage_banner_category', { item_id: banner.id, item_name: banner.title })

    window.open(banner.url)
  }

  const displayPromotions = generatingPromotions(promotions, loading, breakpoint === 'xs')

  if (!loading && promotions.length === 0) return null

  return (
    <div className={`${className} py-3 category-promotions`}>
      <Container className=".container-md">
        {categoryLoading ? <TitlePlaceholder /> : (
          <div className="d-flex">
            <Link to={`/${category.slug}`} onClick={onTitleClick} className="d-flex align-items-center text-decoration-none">
              <img src={category.icon_url} alt={category.name} width={25} height={25} />
              <h5 className="fw-bold mb-0 ms-2">{category.name}</h5>
            </Link>
            <Link to={`/${category.slug}`} onClick={onSeeMoreClick} className="ms-auto">
              <p className="mb-0 fw-bold text-decoration-underline">See More</p>
            </Link>
          </div>
        )}

        {breakpoint === 'xs' ? (
          <Carousel touch fade interval={3000} indicators={false} controls={false}>
            {chunk(displayPromotions, 4).map((groupPromotions, index) => (
              <Carousel.Item key={index}>
                <div key={index} className="promotion-row mt-3">
                  {
                    groupPromotions.map(promotion => (
                      <div key={promotion.id} className="promotion-col">
                        {promotion.showAvailableAds ? <AvailableAds /> : <PromotionItem promotion={promotion} loading={loading} />}
                      </div>
                    ))
                  }
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <Row className="mt-3">
            {displayPromotions.map(promotion => (
              <Col sm={3} lg={2} key={promotion.id}>
                <PromotionItem promotion={promotion} loading={loading} />
              </Col>
            ))}
          </Row>
        )}

        {banners.length > 0 && (
          <Row>
            <Col sx={12} className="text-center mt-3 mb-2">
              <img
                src={banners[0].image_url}
                alt={banners[0].title}
                style={{ borderRadius: 0, width: isSmallScreen ? '100%' : '80%', cursor: 'pointer' }}
                onClick={e => onBannerClick(banners[0])}
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default CategoryPromotions
