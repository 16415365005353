import React from 'react'

import TopHeader from './TopHeader'
import Navbar from './Navbar'
import Footer from './Footer'

import ScrollToTop from './ScrollToTop'

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <ScrollToTop>
        <TopHeader />
        <Navbar />
        {children}
        <Footer />
      </ScrollToTop>
    </div>
  )
}

export default Layout
