import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Container, Form, InputGroup, Button, Row, Col } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa'
import { getAnalytics, logEvent } from "firebase/analytics"

const analytics = getAnalytics()

const CLNavbar = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState("")

  const onKeyUp = e => {
    const key = e.code || e.keyCode

    if (key === 'Enter' || key === 13) {
      handleSearch('enter')
      e.target.blur()
    }
  }

  const handleSearch = action => {
    navigate(`/search?q=${search}`)

    logEvent(analytics, 'search', { search })
    logEvent(analytics, `search_by_${action === 'enter' ? 'enter' : 'click'}`, { q: search })
  }

  return (
    <Container className="top-nav py-2">
      <Row>
        <Col xs={12} sm={4} className="d-flex justify-content-center justify-content-sm-start">
          <Link to="/">
            <img
              src={require('../assets/logo.png')}
              height="60"
              className="d-inline-block align-top my-2"
              alt="CS Lab Logo"
            />
          </Link>
        </Col>
        <Col xs={10} sm={8} className="d-flex mx-auto align-items-center">
          <InputGroup className="search my-2">
            <Form.Control
              value={search}
              placeholder="Search"
              onChange={e => setSearch(e.target.value)}
              onKeyUp={onKeyUp}
            />
            <Button onClick={handleSearch}>
              <FaSearch color="white" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  )
}

export default CLNavbar
