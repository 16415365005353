import Immutable from 'seamless-immutable'
import { CATEGORY_TYPES } from '../types'

const initialState = Immutable({
  data: [],
  loading: false,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_TYPES.SET_DATA:
      return state
        .set('data', action.data)
        .set('loading', false)

    case CATEGORY_TYPES.SET_LOADING:
      return state.set('loading', action.val)

    default:
      return state
  }
}

export default reducer
