import axios from 'axios'
import { CATEGORY_TYPES } from '../types'
import { endpoint } from '../../utils/constants'

const setCategories = data => ({
  type: CATEGORY_TYPES.SET_DATA,
  data,
})

const setLoading = val => ({
  type: CATEGORY_TYPES.SET_LOADING,
  val
})

const getCategories = () => async dispatch => {
  dispatch(setLoading(true))

  try {
    const { data } = await axios.get(endpoint.categories)
    dispatch(setCategories(data))
  } catch (e) {
    dispatch(setLoading([]))
  }
}

const actions = {
  setLoading,
  setCategories,
  getCategories,
}

export default actions
