import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className="not-found">
      <p className="not-found-code">404</p>
      <p className="not-found-message">Page Not Found</p>
      <Button onClick={() => navigate('/')}>Back to Home</Button>
    </div>
  )
}

export default NotFound
