import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BannerActions from '../../redux/actions/banners'
import CategoryActions from '../../redux/actions/categories'
import useBreakpoint from '../../hooks/useBreakpoint'

import Banner from './Banner'
import Categories from './Categories'
import CategoryPromotions from './CategoryPromotions'

const Home = () => {
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()
  const isSmallScreen = ['xs', 'sm'].includes(breakpoint)

  const { data: banners } = useSelector(state => state.banners)
  const { data: categories, loading: categoryLoading } = useSelector(state => state.categories)

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(CategoryActions.getCategories())
    }
  }, [dispatch, categories])

  useEffect(() => {
    if (banners.length === 0) {
      dispatch(BannerActions.getBanners({ platform: isSmallScreen ? 'mobile' : 'web' }))
    }
  }, [dispatch, banners, isSmallScreen])

  const homepageBanners = banners.reduce((result, elem) => {
    if (elem.placement === 'homepage-category') {
      result[elem.category_id] ||= []
      result[elem.category_id].push(elem)
    }

    return result
  }, {})

  const listingCategories = categories.filter(c => c.image_url)
  const displayCategories = categoryLoading
    ? Array(5).fill()
    : categories.filter(c => c.display_on_homepage)

  return (
    <div data-page="Home">
      <Banner />
      <Categories categories={listingCategories} loading={categoryLoading} />

      {displayCategories.map((category, index) => {
        const categoryBanners = homepageBanners[category?.id] || []

        return (
          <CategoryPromotions
            key={index}
            banners={categoryBanners}
            category={category}
            categoryLoading={categoryLoading}
            className={index === 0 ? "bg-gray" : ""}
          />
        )
      })}
    </div>
  )
}

export default Home
