import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { Container, Row, Col } from 'react-bootstrap'
import { get, size } from 'lodash'
import { AiOutlineShop } from 'react-icons/ai'
import { FaMapMarkerAlt, FaGlobe, FaInstagram, FaTelegramPlane, FaFacebookF } from 'react-icons/fa'
import { BsTelephoneFill } from 'react-icons/bs'
import moment from 'moment'

import {
  TwitterIcon,
  FacebookIcon,
  TelegramIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
} from "react-share"

import PromotionActions from '../../redux/actions/promotions'

import NotFound from '../../components/NotFound'
import PromotionItem from '../../components/PromotionItem'

const generateUrl = url => {
  if (url.includes('http')) {
    const decoredUrl = decodeURIComponent(url)

    return decoredUrl
      .trim()
      .replace("https://l.facebook.com/l.php?u=", "")
      .replace(/(\?|&)fbclid.+/, '')
      .replace(/\s.+/, '')
  }

  return `https://${url}`
}

const numberWithCommas = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

const Promotion = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { detail } = useSelector(state => state.promotions)

  const [imageUrl, setImageUrl] = useState(null)

  const loading = get(detail, [id, 'loading'], true)
  const promotion = get(detail, [id, 'data'])

  useEffect(() => {
    dispatch(PromotionActions.getPromotionDetail(id))
  }, [dispatch, id])

  useEffect(() => {
    if (promotion) {
      setImageUrl(promotion.image_url)
    }
  }, [promotion])

  if (promotion && size(promotion) === 0) {
    return (
      <NotFound />
    )
  }

  return (
    <Container data-page="promotion-detail">
      {promotion && (
        <Helmet>
          <meta name="description" content={`${promotion.merchant?.name} - 365 Promotion`} />
          <meta property="og:title" content={promotion.title} />
          <meta property="og:image" content={promotion.image_url} />
        </Helmet>
      )}

      {promotion && (
        <Row className="mt-3 mb-5">
          <Col sm={12} md={5}>
            <div className="gallery pe-5">
              <img src={imageUrl} alt={promotion.title} className="w-100 mb-3" />

              {promotion.images.length > 0 && (
                <div className="d-flex justify-content-center overflow-auto hide-scrollbar">
                  <div
                    onClick={() => setImageUrl(promotion.image_url)}
                    className="me-2 mb-2 thumbnail clickable"
                    style={{ backgroundImage: `url(${promotion.image_url})` }}
                  />

                  {promotion.images.map(image => (
                    <div
                      key={image.id}
                      className="me-2 mb-2 thumbnail clickable"
                      onClick={() => setImageUrl(image.image_url)}
                      style={{ backgroundImage: `url(${image.image_url})` }}
                    />
                  ))}
                </div>
              )}
            </div>

          </Col>
          <Col sm={12} md={5}>
            <div className="promotion-content">
              <h4 className="fw-bold">{promotion.title}</h4>
              {promotion.price && (
                <div className="price-container mb-3">
                  <p className="mb-0 text-decoration-line-through discount-price">
                    $ {numberWithCommas(parseFloat(promotion.price).toFixed(2))}
                  </p>
                  <p className="fw-bold mb-0 price">
                    $ {numberWithCommas(parseFloat(promotion.discount_price).toFixed(2))}
                  </p>
                </div>
              )}
              <div className="d-flex align-items-center">
                <span className={`promotion-badge ${promotion.discount_type.toLowerCase()}`}>
                  {promotion.discount_label}
                </span>
                <p className="mb-0 ms-2">Expired Date: {moment(promotion.expired_date, 'YYYY-MM-DD').format("DD-MMM-YYYY")}</p>
              </div>

              {
                (
                  promotion.merchant?.name ||
                  promotion.merchant?.address ||
                  promotion.merchant?.website ||
                  promotion.merchant?.tel ||
                  promotion.merchant?.facebook ||
                  promotion.merchant?.instagram ||
                  promotion.merchant?.telegram
                ) && (
                  <div className="merchant">
                    {promotion.merchant?.name && (
                      <div className="merchant-info">
                        <div className="icon-wrapper"><AiOutlineShop size={20} /></div>
                        <span className="ms-3 text-black">
                          {promotion.merchant.name}
                          {promotion.merchant?.location && (
                            <a
                              className="text-black ms-2"
                              href={generateUrl(promotion.merchant?.location)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              (View Map)
                            </a>
                          )}
                        </span>
                      </div>
                    )}
                    {promotion.merchant?.address && (
                      <div className="merchant-info">
                        <div className="icon-wrapper"><FaMapMarkerAlt size={18} /></div>
                        <span className="ms-3 text-black">{promotion.merchant.address}</span>
                      </div>
                    )}
                    {promotion.merchant?.website && (
                      <div className="merchant-info">
                        <div className="icon-wrapper"><FaGlobe size={18} /></div>
                        <span className="ms-3 w-100 text-truncate">
                          <a
                            className="text-decoration-none text-black"
                            href={generateUrl(promotion.merchant.website)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {generateUrl(promotion.merchant.website)}
                          </a>
                        </span>
                      </div>
                    )}

                    {promotion.merchant?.tel && (
                      <div className="merchant-info">
                        <div className="icon-wrapper"><BsTelephoneFill size={17} /></div>
                        <span className="ms-3">
                          <a className="text-decoration-none text-black" href={`tel:${promotion.merchant.tel}`}>{promotion.merchant.tel}</a>
                        </span>
                      </div>
                    )}

                    {promotion.merchant?.facebook && (
                      <div className="merchant-info">
                        <div className="icon-wrapper"><FaFacebookF size={17} /></div>
                        <span className="ms-3 w-100 text-truncate">
                          <a
                            className="text-decoration-none text-black"
                            target="_blank"
                            rel="noreferrer"
                            href={promotion.merchant.facebook}
                          >
                            {generateUrl(promotion.merchant.facebook)}
                          </a>
                        </span>
                      </div>
                    )}

                    {promotion.merchant?.telegram && (
                      <div className="merchant-info">
                        <div className="icon-wrapper"><FaTelegramPlane size={17} /></div>
                        <span className="ms-3 w-100 text-truncate">
                          <a
                            className="text-decoration-none text-black"
                            target="_blank"
                            rel="noreferrer"
                            href={promotion.merchant.telegram}
                          >
                            {generateUrl(promotion.merchant.telegram)}
                          </a>
                        </span>
                      </div>
                    )}

                    {promotion.merchant?.instagram && (
                      <div className="merchant-info">
                        <div className="icon-wrapper"><FaInstagram size={17} /></div>
                        <span className="ms-3 w-100 text-truncate">
                          <a
                            className="text-decoration-none text-black"
                            target="_blank"
                            rel="noreferrer"
                            href={promotion.merchant.instagram}
                          >
                            {generateUrl(promotion.merchant.instagram)}
                          </a>
                        </span>
                      </div>
                    )}
                  </div>
                )
              }

              {promotion.description && (
                <div>
                  <h6 className="fw-bold mt-5">Term and Conditions</h6>
                  <p className="break-line">{promotion.description}</p>
                </div>
              )}

              <div>
                <h6 className="fw-bold mt-5">Share this promotion</h6>
                <div>
                  <FacebookShareButton
                    url={window.location.href}
                    quote={promotion.title}
                  >
                    <FacebookIcon size={28} round className="me-1" />
                  </FacebookShareButton>
                  <FacebookMessengerShareButton
                    url={window.location.href}
                    quote={promotion.title}
                    appId="422449106586942"
                  >
                    <FacebookMessengerIcon size={28} round className="me-1" />
                  </FacebookMessengerShareButton>
                  <TelegramShareButton
                    url={window.location.href}
                    quote={promotion.title}
                  >
                    <TelegramIcon size={28} round className="me-1" />
                  </TelegramShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    quote={promotion.title}
                  >
                    <TwitterIcon size={28} round className="me-1" />
                  </TwitterShareButton>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={2}>
          </Col>
        </Row>
      )}

      {promotion?.related?.length > 0 && (
        <>
          <Row>
            <Col xs={12}>
              <div className="d-flex align-items-center text-decoration-none">
                <img src={require('../../assets/related-icon.webp')} alt="Related Category" width={25} height={25} />
                <h5 className="fw-bold mb-0 ms-2">Related Promotions</h5>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            {loading ? (
              Array.from({ length: 6 }).map((_, index) => (
                <Col xs={6} sm={4} lg={2} key={index}><PromotionItem loading /></Col>
              ))
            ) : (
              promotion.related.map(promotion => (
                <Col xs={6} sm={4} lg={2} key={promotion.id}>
                  <PromotionItem promotion={promotion} />
                </Col>
              ))
            )}
          </Row>
        </>
      )}
    </Container>
  )
}

export default Promotion
