export const contact = {
  phone: '+855 16 89 50 50',
  email: 'info@365-promotion.com',
  facebook: 'https://www.facebook.com/365-Promotion-102137748981094/',
  instagram: '/',
  youtube: '/',
  telegram: 'https://t.me/channel365promotion',
}

const baseUrl = "https://node.365-promotion.com"
// const baseUrl = "http://localhost:5000"

export const endpoint = {
  banners: `${baseUrl}/api/v1/banners`,
  categories: `${baseUrl}/api/v1/categories`,
  promotions: `${baseUrl}/api/v1/promotions`,
}
