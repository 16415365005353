import { combineReducers } from 'redux'
import categories from './categories'
import promotions from './promotions'
import banners from './banners'

export default combineReducers({
  banners,
  categories,
  promotions,
})
